"use strict";
require("dotenv").config();
/**
 * Configuración del plugin Axios.JS cliente HTTPS
 */

// Importaciones
import { v4 as uuidv4 } from "uuid";
const axios = require("axios");
import Vue from "../main.js";

/**
 * Función encargada de generar un TRACK ID que se enviara dentro de cada petición
 */
function trackId() {
  return Math.floor(Math.random() * 9000001) + 999999;
}

// Configuraciones básicas de Axios.JS
let config = {
  baseURL: process.env.VUE_APP_API_URL,
  withcredentials: true,
  headers: {
    "Content-Type": "application/json",
    "X-Application-Name": "smartpill-app",
    "X-Client-Name": "smartpill-app",
    "X-Flow-Id": uuidv4(),
    "X-Platform-Id": "browser",
    "X-Request-Id": uuidv4(),
    "X-Track-Id": `${trackId()}`,
    "X-Access-Token": "",
  },
  params: {},
};
let _axios = axios.create(config);

_axios.interceptors.request.use(
  /**
   * Configuración de envio de _axios.js
   * @param {*} config
   */
  function(config) {
    // Validar la existencia de un token de autorización.
    config.withcredentials = true;
    config.method = config.method.toUpperCase();
    config.headers["X-Http-Method"] = config.method;
    config.headers["Authorization"] = `Bearer ${sessionStorage.getItem(
      "token"
    )}`;
    return config;
  },
  /**
   * Instancia de error previo al envio de la solicitud HTTP.
   * @param {*} error
   */
  function(error) {
    return Promise.reject(error);
  }
);

_axios.interceptors.response.use(
  /**
   * Intercepción de la respuesta obtenida tras la solicitud HTTP.
   * @param {*} response
   */
  function(response) {
    return response;
  },
  /**
   * Intercepción de la respuesta de error, solo obtenida en caso de fallo en la solicitud HTTP.
   * @param {*} error
   */
  function(error) {
    if (error.response.status === 403) {
      sessionStorage.clear();
      Vue.$router.push("/login");
      Vue.$toast.error("Sessión expirada, favor inicie sesión nuevamente");
    }
    return Promise.reject(error);
  }
);

export default _axios;
