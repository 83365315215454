<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: "App",
  mounted() {
    this.checkDark();
  },
  methods: {
    checkDark() {
      let bool = localStorage.getItem("dark") === "true" ? true : false;
      this.$vuetify.theme.dark = bool;
    },
  },
};
</script>

<style>
@import url("../node_modules/animate.css/animate.min.css");

* {
  margin: 0;
  padding: 0;
}
.min-height {
  min-height: 100vh;
}
.v-card,
.v-sheet.v-card,
.rounded {
  border-radius: 8px !important;
}
.no-rounded {
  border-radius: 0px !important;
}
</style>
