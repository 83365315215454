import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "",
    component: () =>
      import(/* webpackChunkName: "interfaz" */ "../views/Portal.vue"),
    children: [
      {
        path: "/inicio",
        name: "home",
        component: () =>
          import(/* webpackChunkName: "home" */ "../views/Home.vue"),
      },

      {
        path: "/sobre",
        name: "about",
        component: () =>
          import(/* webpackChunkName: "about" */ "../views/About.vue"),
      },
      {
        path: "/recetas",
        name: "recetas",
        component: () =>
          import(/* webpackChunkName: "recetas" */ "../views/Recetas.vue"),
      },
      {
        path: "/alarmas",
        name: "alarmas",
        component: () =>
          import(/* webpackChunkName: "alarmas" */ "../views/Alarmas.vue"),
      },
      {
        path: "/configuracion",
        name: "configuracion",
        component: () =>
          import(
            /* webpackChunkName: "configuracion" */ "../views/Configuracion.vue"
          ),
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Login.vue"),
  },
  {
    path: "/registro",
    name: "Registro",
    component: () =>
      import(/* webpackChunkName: "login" */ "../views/Registro.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem("token");
  let email = sessionStorage.getItem("email");
  async function validateRoute() {
    try {
      if (!token && !email) next("/login");
      else next();
    } catch (e) {
      if (e) next("/login");
    }
  }

  // Validaciones básicas previa validación.
  if (to.fullPath === "/login") next();
  else if (to.fullPath === "/registro") next();
  else if (to.fullPath === "/portal/inicio" && !token && !email) next("/login");
  else if (to.fullPath === "/login" && token && email) next("/portal/inicio");
  else if (from.fullPath === "/login" && to.fullPath === "/portal/inicio")
    next();
  else validateRoute();
});

export default router;
