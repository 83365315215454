import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./plugins/axios";
import vuetify from "./plugins/vuetify";
import "@babel/polyfill";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import wb from "./registerServiceWorker";
import VuetifyToast from "vuetify-toast-snackbar";

Vue.prototype.$workbox = wb;

//import VueMqtt from "vue-mqtt";

//Vue.use(VueMqtt, 'ws://test.mosquitto.org:8080/ws', {clientId: 'clientId-3Kx03pKnM2'})

Vue.config.productionTip = false;

let _vue = new Vue({
  router,
  axios,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

export default _vue;

const ToastConfig = {
  $vuetify: vuetify.framework,
  x: "center",
  y: "bottom",
  color: "info",
  icon: "mdi-info",
  timeout: 3000,
  queueable: true,
  dismissable: true,
  showClose: false,
  closeIcon: "mdi-close",
  closeColor: "white",
  autoHeight: false,
  multiLine: true,
  vertical: false,
  shorts: {
    custom: {
      color: "purple",
    },
  },
  property: "$toast",
};
Vue.use(VuetifyToast, ToastConfig);
