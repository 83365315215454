import Vue from "vue";
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
import es from "vuetify/es5/locale/es";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      light: {
        primary: "#01A0C5",
        secondary: "#c52501",
        accent: "#01c587",
        triadic: "#c501a1",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        background: "#F0F2F5",
      },
      dark: {
        primary: "#01A0C5",
        secondary: "#c52501",
        accent: "#01c587",
        triadic: "#c501a1",
        error: "#FF5252",
        info: "#2196F3",
        success: "#4CAF50",
        warning: "#FFC107",
        background: "#121212",
      },
    },
  },
  lang: {
    locales: { es },
    current: "es",
  },
});
